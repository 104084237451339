<template>
  <div class="box">
    <div class="jbxx">
      <div class="jbtop">
        <div class="icon"></div>
        <div class="tit">基本信息</div>
      </div>
      
      <el-form ref="form" :model="form" label-width="200px">
        <el-row>
          <el-col :span="7">
            <el-form-item label="所属商家">
              <el-input style="width: 170px;" @focus="focus(1)" v-model="form.belongBusinessName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="一级分类">
              <el-input style="width: 170px;" @focus="focusfl(1)" v-model="form.parentClassifyName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="二级分类">
              <el-input style="width: 170px;" @focus="focusfltwo()" v-model="form.classifyName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="团购名称">
              <el-input style="width: 170px;" v-model="form.commodityName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="适用门店">
              <el-tooltip :content="form.businessName" placement="top">
                <el-input style="width: 170px;"  @focus="focus(2)" v-model="form.businessName"></el-input>
              </el-tooltip>
              <!-- <div>
                <el-tag 
                      :key="tag"
                      v-for="tag in taglist"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(i,tag)">
                      {{tag}}
                </el-tag>
              </div> -->
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="用法">
              <el-input style="width: 170px;" v-model="form.direction" placeholder="到店核销/仅堂食/可外带"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
           
            <el-form-item label="团购使用时间" >
              <el-input style="width: 170px;" v-model="form.useTime" placeholder="营业时间内可用"></el-input>
              <!-- <el-time-picker
                is-range
                v-model="value1"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围">
              </el-time-picker> -->
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="服务保障">
              <el-input style="width: 170px;" v-model="form.guarantee"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="团购开始日期">
              <el-date-picker
              style="width:170px;"
                v-model="form.buyStartTime"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd">

              </el-date-picker>
              <!-- <el-input v-model="form.buyStartTime"></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="团购结束日期">
              <el-date-picker
              style="width: 170px;"
                v-model="form.buyEndTime"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                >
              </el-date-picker>
              <!-- <el-input v-model="form.buyEndTime"></el-input> -->
            </el-form-item>
          </el-col>
          <!-- <el-col :span="7">
            <el-form-item label="团购有效期">
              <el-input style="width: 170px;" v-model="form.expiration"></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
       
        <div style="margin-left: 20px;">团购规格：</div>
        <el-row>
          <el-col :span="7">
            <el-form-item label="零售价">
              <el-input style="width: 170px;" v-model="form.retailPrice"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="团购价">
              <el-input style="width: 170px;" v-model="form.buyPrice"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="规格名称">
              <el-input style="width: 170px;" v-model="form.specificationName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="打包费">
              <el-input style="width: 170px;" v-model="form.packingPrice"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="上架库存">
              <el-input style="width: 170px;" v-model="form.inventory"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="库存为0时是否继续售卖">
              <el-switch v-model="form.sellWithNoInventory" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="该商品是否不计入库存">
              <el-switch v-model="form.unInventory" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="是否上推荐">
              <el-switch v-model="form.isRecommend" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        
        <div style="margin-left: 20px;">团购详情：</div>
        <div class="tab">
          <div class="add">
            <el-button type="primary" @click="addfl" icon="el-icon-circle-plus-outline">新增分类</el-button>
            <el-button type="primary" @click="add" icon="el-icon-circle-plus-outline">新增商品</el-button>
          </div>
          <el-table :data="commodityDetailList" style="width: 100%" height="calc(100vh - 480px)" border
            :header-cell-style="{
              'background-color': '#f2f2f2',
              'text-align': 'center',
              border: '1px solid #ddd',
            }"
            :span-method="spanMethod"
            >
            <!-- <el-table-column type="selection" width="50"> </el-table-column> -->

            <el-table-column prop="content" label="标题" align="center">
              <template slot-scope="scope">
                <el-input style="border: 0px solid #b8becc;" v-model="scope.row.content" placeholder="请输入标题"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="价格（元）" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.price" placeholder="请输入价格"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="number" label="数量" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.number" placeholder="请输入数量"></el-input>
              </template>
            </el-table-column>


            <el-table-column prop="scope" label="操作" width="250" align="center">
              
              <template slot-scope="scope">
                <template>
              <el-popconfirm title="这是一段内容确定删除吗？" @onConfirm="sc(scope.row)"
                @confirm="sc(scope.row)">
                <el-button type="text" slot="reference">删除</el-button>
              </el-popconfirm>
            </template>
                <!-- <el-button type="text" style="color: red;" @click="sc(scope.row)">删除</el-button> -->
              </template>
            </el-table-column>


          </el-table>
        </div>
        <div style="margin-left: 20px;">使用规则（可多选）：</div>
        <el-row>
          <el-col :span="6">
            <el-form-item label="需提前预约">
              <el-switch v-model="form.onlyOne" active-value=1 inactive-value=0>
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="无需预约高峰期可能需要排队">
              <el-switch v-model="form.onlyTwo" active-value=1 inactive-value=0>
              </el-switch>
            </el-form-item>
          </el-col>
         
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="可使用包厢">
              <el-switch v-model="form.onlyThree" active-value=1 inactive-value=0>
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="不可使用包厢">
              <el-switch v-model="form.onlyFour" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
         
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="每次限用一张">
              <el-switch v-model="form.onlyFive" active-value=1 inactive-value=0>
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单次消费不限制使用张数">
              <el-switch v-model="form.onlySix" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
         
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="全场通用">
              <el-switch v-model="form.onlySeven" active-value=1 inactive-value=0>
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="除酒水、饮料外可用">
              <el-switch v-model="form.onlyEight" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
         
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="不与店内优惠同享">
              <el-switch v-model="form.onlyNine" active-value=1 inactive-value=0>
              </el-switch>
            </el-form-item>
          </el-col>
          
         
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="发票问题请联系平台">
              <el-switch v-model="form.onlyTen" active-value=1 inactive-value=0>
              </el-switch>
            </el-form-item>
          </el-col>
          
         
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="每次可用（张）">
              <el-input v-model="form.useNum"></el-input>
            </el-form-item>
          </el-col>
          
         
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="其他">
              <el-input type="textarea" style="width: 350px;min-height: 100px;" v-model="form.other"></el-input>
            </el-form-item>
          </el-col>
          
         
        </el-row>
       
        <!-- <el-row>
          <el-col :span="4">
            <el-form-item label="仅堂食">
              <el-switch v-model="form.onlyDine" active-value=1 inactive-value=0>
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="不可使用包间">
              <el-switch v-model="form.unUsePrivateRooms" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label-width="300px" label="无需预约，高峰期间可能需要等座位">
              <el-switch v-model="form.unBooking" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label-width="300px" label="购商品不能与其他优惠同时使用">
              <el-switch v-model="form.noContract" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :span="24">
            <el-form-item label="商品详情轮播：" prop="contactName">
            
            <!-- <el-upload
              class="avatar-uploader xin"
              :action="$uploadURL"
              :show-file-list="false"
              :on-success="handleSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <div v-if="list.length > 0" class="flex">
                <div
                  style="margin: 5px"
                  class="imgBox"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <img :src="item" class="avatar" alt="" />
                  <div class="img-icon">
                    <i
                      class="el-icon-zoom-in"
                      @click.stop="onPreview(item)"
                    ></i>
                    <i class="el-icon-delete" @click.stop="handelRemove(index)"></i>
                  </div>
                </div>
              </div>
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload> -->
              <div v-if="list.length > 0" class="flex">
                <div
                  class="imgBox"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <img :src="item" class="upload-img" alt="" />
                  <div class="img-icon">
                    <i class="el-icon-zoom-in" @click.stop="onPreview(item)"></i>
                    <i class="el-icon-delete" @click.stop="handelRemove(index)"></i>
                  </div>
                </div>
              </div>
                <div class="list-img-box">
                  <div class="upload-btn" style="" @click="uploadPicture('bigImgList')">
                    <i class="el-icon-plus" style="font-size: 30px;"></i>
                  </div>
                </div>
              <div style="font-size: 12px;padding-left: 5px;color: #60627f;">图片建议尺寸(750 × 343)</div>
            </el-form-item>
            <!-- <el-form-item label="商品大图：" prop="contactName">
              <el-upload class="avatar-uploader" :action="$uploadURL"
                :show-file-list="false" :on-success="handleAvatarSuccess">
                <img v-if="form.bigImg" :src="form.bigImg" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item> -->
          </el-col>
          <el-col :span="24">
            <el-form-item label="商品视频：" prop="contactName">
              <el-upload class="avatar-uploader" :action="$uploadURL"
                :show-file-list="false" :on-success="handleAvatarSuccesstwo">
                
                <video v-if="form.buyVideo" :src="form.buyVideo" class="avatar" controls></video>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div style="font-size: 12px;padding-left: 5px;color: #60627f;">请上传5M以内的视图视频</div>
            </el-form-item>
          </el-col>
            <el-col :span="24">
              <el-form-item label="商品logo：" prop="contactName">
                <div class="list-img-box">
                  <div v-if="form.logoImg !== ''" class="imgBox">
                    <img class="upload-img" :src="form.logoImg" style='width:150px;height:150px' @click="uploadPicture('logoImg')" alt="自定义商品logo">
                    <div class="img-icon">
                      <i class="el-icon-zoom-in" @click.stop="onPreview(form.logoImg)"></i>
                      <i class="el-icon-delete" @click.stop="handelRemove('logo')"></i>
                    </div>
                  </div>
                  <div v-else class="upload-btn" style="" @click="uploadPicture('logoImg')">
                    <i class="el-icon-plus" style="font-size: 30px;"></i>
                  </div>
                </div>
                <!-- <el-upload
                  class="avatar-uploader"
                  :action="$uploadURL"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccesslogo"
                  >
                  <img v-if="form.logoImg" :src="form.logoImg" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload> -->
              <div style="font-size: 12px;padding-left: 5px;color: #60627f;">图片建议尺寸(1 × 1)</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="bottom-but">
            <el-button size="medium" @click="close()">取消 </el-button>
            <el-button size="medium" type="primary" @click="onSubmit()">保存</el-button>
          </div>
        </el-row>
      </el-form>
    </div>
    <el-dialog title="新增团购商品" :visible.sync="showDialog" width="600px" height="1000px">
      <el-form label-width="100px" label-position="left">
          <el-row>
            <el-col span="16">
              <el-form-item label="商品名称">
                <el-input v-model="dia.content" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="16">
              <el-form-item label="价格">
                <el-input v-model="dia.price" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="16">
              <el-form-item label="数量">
                <el-input v-model="dia.number" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
         
         
          
          <div class="but" style="text-align: center;">
            <el-button size="medium" @click="showDialog = false">取消 </el-button>
            <el-button size="medium" type="primary" @click="bc()">保存 </el-button>
          </div>
        </el-form>
     

    </el-dialog>
    <el-dialog title="新增团购分类" :visible.sync="showDialogfl" width="600px" height="1000px">
      <el-form label-width="100px" label-position="left">
          <el-row>
            <el-col span="16">
              <el-form-item label="分类">
                <el-input v-model="dia.content" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
         
         
          
          <div class="but" style="text-align: center;">
            <el-button size="medium" @click="showDialogfl = false">取消 </el-button>
            <el-button size="medium" type="primary" @click="bcfl()">保存 </el-button>
          </div>
        </el-form>
     

    </el-dialog>
    <el-dialog title="选择商家" :visible.sync="userDialog" width="500px" height="400px">
      <el-form label-width="80px" label-height="40px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商家名称：" prop="contactName">
              <el-input v-model="name" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex; justify-content: right;">
           
            <el-button type="primary" @click="searchsj" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>

      </el-form>
      <div class="dia">
        <el-table :data="restaurants" style="width: 100%" height="calc(100vh - 480px)"
          @selection-change="handleSelectionChange" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
          <el-table-column type="selection" width="50"> </el-table-column>


          <el-table-column prop="businessName" label="商家名称" align="center">
          </el-table-column>
         
          <el-table-column prop="creatTime" label="创建时间" align="center">

          </el-table-column>


        </el-table>


        <div class="diafour" style="text-align: center;">
          <el-button type="danger" @click="userDialog = false">取消</el-button>
          <el-button type="primary" @click="userDialog = false">确定 </el-button>
        </div>
      </div>

    </el-dialog>
    <el-dialog title="商品分类" :visible.sync="userDialogfl" width="500px" height="400px">
      <div class="dia">
        <el-table :data="restaurantsfl" style="width: 100%" height="calc(100vh - 480px)"
          @selection-change="handleSelectionChangefl" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
          <el-table-column type="selection" width="50"> </el-table-column>


          <el-table-column prop="classifyName" label="分类名称" align="center">
          </el-table-column>
         
          <el-table-column prop="creatTime" label="创建时间" align="center">

          </el-table-column>


        </el-table>


        <div class="diafour" style="text-align: center;">
          <el-button type="danger" @click="userDialogfl = false">取消</el-button>
          <el-button type="primary" @click="flshow()">确定 </el-button>
        </div>
      </div>

    </el-dialog>
    <el-dialog title="商品二级分类" :visible.sync="userDialogtwofl" width="500px" height="400px">
      <div class="dia">
        <el-table :data="fltwolist" style="width: 100%" height="calc(100vh - 480px)"
          @selection-change="handleSelectionChangetwofl" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
          <el-table-column type="selection" width="50"> </el-table-column>


          <el-table-column prop="classifyName" label="分类名称" align="center">
          </el-table-column>
         
          <el-table-column prop="creatTime" label="创建时间" align="center">

          </el-table-column>


        </el-table>


        <div class="diafour" style="text-align: center;">
          <el-button type="danger" @click="userDialogtwofl = false">取消</el-button>
          <el-button type="primary" @click="userDialogtwofl = false">确定 </el-button>
        </div>
      </div>

    </el-dialog>
    <!-- 剪裁组件弹窗 -->
    <el-dialog
        title="裁切封面"
        :visible.sync="cropperModel"
        width="1200px"
        center
       >
     <cropper-image
        v-if="cropperModel"
        :Name="cropperName" :New="cropperModel"
        :AutoCropWidth="AutoCropWidth" :AutoCropHeight="AutoCropHeight" :FixedNumber="FixedNumber"
        @uploadImgSuccess = "handleUploadSuccess"
        ref="child">
     </cropper-image>
    </el-dialog>
    <!--查看大封面-->
    <el-dialog
        title="查看图片"
        :visible.sync="imgVisible"
        class="previewDialog"
        center>
      <img :src="oldpreview" v-if="imgVisible" style="width: 100%" alt="查看">
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from "./uploadImgList.vue";
import CropperImage from "@/components/CropperImage.vue";
export default {
  components:{
    uploadImg,
    CropperImage
  },
  data() {
    return {
      name:'', //选择商家名称
      list:[],
      form: {
        // belongBusiness:'',
        commodityName: '',
        businessId: '',
        direction: '',
        buyStartTime: '',
        buyEndTime: '',
        expiration: '',
        useTime: '',
        guarantee: '',
        sellWithNoInventory: '',
        unInventory: '',
        isRecommend: '',
        retailPrice: '',
        buyPrice: '',
        specificationName: '',
        packingPrice: '',
        inventory: '',
        commodityDetailList: [],

        onlyOne: '',
        onlyTwo: '',
        onlyThree: '',
        onlyFour: '',
        onlyFive: '',
        onlySix: '',
        onlySeven: '',
        onlyEight: '',
        onlyNine: '',
        onlyTen: '',


        onlyDine: '',
        unUsePrivateRooms: '',
        unBooking: '',
        noContract: '',
        buyVideo: '',
        bigImg: '',
        logoImg:'',
        showDialogfl:false
      },
      tableData: [
        { name: 'wdz', price: '21', number: 12 }
      ],
      showDialog: false,
      showDialogfl: false,
      img1: '',
      dia: {
        content: '',
        price: '',
        number: ''
      },
      commodityDetailList: [],
      id: '',
      restaurants:[],
      userDialog:false,
      restaurantsfl:[],
      userDialogfl:false,
      fltwolist:[],
      userDialogtwofl:false,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fullView:[],
      focusindex:'',
      focusflindex:'',
      businessId:'',
      flId:'',
      taglist:[],
      pageNum: null,
      options: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      }],
      //裁切图片参数
      cropperModel:false,
      cropperName:'',
      AutoCropWidth: null,//宽
      AutoCropHeight: null,//高
      FixedNumber: null,//
      oldpreview: '',//预览图
      imgVisible: false,
      val:null,

    };
  },
  created() {
    console.log(this.$route.query.id);
    var that = this;
    that.$http.post('/business/queryBusinessList',{}).then(response => {
      console.log(response.data.data,'response.data.data');
      
        that.restaurants = response.data.data
        console.log(that.restaurants,'that.restaurants');
        
        
        
      })
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.pageNum = this.$route.query.pageNum
      this.$http.post('/commodity/queryOne', {
        id: this.id
      }).then(response => {
        console.log(response.data.data);
        that.form = response.data.data;
        that.commodityDetailList = response.data.data.commodityDetailList
        that.list = response.data.data.bigImgList
        // 编辑页弹窗
        // that.form.belongBusiness= that.form.belongBusiness
        // that.form.belongBusinessName= that.form.businessName
        this.businessId = that.form.belongBusiness
        that.$http.post('/commodityType/queryCommodityTypeListByBusinessId',{
          businessId: that.form.belongBusiness,
          commodityType:1,
        }).then(response => {
          that.restaurantsfl = response.data.data
          let _row = that.restaurantsfl[that.restaurantsfl.findIndex(item=>item.classify == that.form.parentClassify)]
          that.fltwolist = _row.commodityTypeSonList;
        })
      })
    }

  },
  mounted() {

  },
  methods: {
    spanMethod({ row, column, rowIndex, columnIndex }) {
      // Check if both price and number are empty
      if (!row.price && !row.number) {
        if (column.property === 'content') {
          return { rowspan: 1, colspan: 3 }; // Merge content with price and number columns
        } else if (column.property === 'price' || column.property === 'number') {
          return { rowspan: 0, colspan: 0 }; // Hide price and number columns
        }
      }
      return { rowspan: 1, colspan: 1 }; // Default case
    },
    //图片裁剪设置
    uploadPicture(name){
      this.cropperName = name;
      switch(name){
        case 'bigImgList':
        this.AutoCropWidth = 375//宽
        this.AutoCropHeight = 171.5//高
          this.FixedNumber = [750,343]//比例
          break;
        case 'logoImg':
          this.AutoCropWidth = 150//宽
          this.AutoCropHeight = 150//高
          this.FixedNumber = [1,1]//比例
          break;
      }
      this.cropperModel = true;
    },
    //图片上传成功后
    handleUploadSuccess (data){
      console.log(data)
      switch(this.cropperName){
        case 'bigImgList':
          this.list.push(data.url)
          console.log('最终输出'+this.cropperName)
          break;
        case 'logoImg':
          this.form.logoImg = data.url;
          console.log('最终输出'+this.cropperName)
          break;
      }
      this.cropperName = ''
      this.cropperModel = false;
    },
    //图片预览
    onPreview(url){
      this.oldpreview = url
      this.imgVisible = true;
    },
    handleRemove(file) {
        console.log(file);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      handleDownload(file) {
        console.log(file);
      },
    sc(row){
      console.log(row.content);
      console.log(this.commodityDetailList);
      
      this.commodityDetailList = this.commodityDetailList.filter(item => item.content !== row.content);
      console.log(this.commodityDetailList);
    },
    handleSelectionChange(val){
      console.log(val);
      
      // this.form.businessName= val[0].businessName
      if(this.focusindex == 1){
        
        this.form.belongBusiness= val[0].id
        this.form.belongBusinessName= val[0].businessName
       this.businessId = val[0].id
      } else {
        // this.taglist = val.map(item=>{
        //   return item.businessName
        // })
       
        this.form.businessId = val.map(item=>{
          return item.id
        })
        const list = val.map(item=>{
          return item.businessName
        })
        this.form.businessName = list.toString()
        // this.form.businessName= val.map(item=>{
        //   return item.businessName
        // })
      }
      

    },
    focus(index){
      var that = this;
      that.name = ''
      that.focusindex = index
      that.$http.post('/business/queryBusinessList',{}).then(response => {
        that.restaurants = response.data.data
      })
      that.userDialog = true

    },
    handleSelectionChangefl(val){
      console.log(val,'val');
      this.val = val
      this.fltwolist = val[0].commodityTypeSonList;
      this.form.parentClassifyName= val[0].classifyName
      this.form.parentClassify = val[0].classify

    },
    flshow(){
      this.userDialogfl = false
      if(this.val){
        return
      }else {
        this.form.parentClassifyName= ''
        this.form.parentClassify = ''

        this.form.classifyName= ''
        this.form.classify= ''
      }
    },
    focusfl(index){
      var that = this;
      console.log(that.businessId);
      that.focusflindex = index
      that.$http.post('/commodityType/queryCommodityTypeListByBusinessId',{
        businessId: that.businessId,
        commodityType:1,
      }).then(response => {
        that.restaurantsfl = response.data.data
      })
      that.userDialogfl = true
    },
    focusfltwo(){
      this.userDialogtwofl = true
    },
    handleSelectionChangetwofl(val){
      this.form.classifyName= val[0].classifyName
      this.form.classify= val[0].classify
    },
    
    handleSuccess(response, file, fileList) {
      this.list.push(response.data.url)
      console.log(response.data.url);
    },
    onSubmit() {
      var that = this;
      this.form.commodityDetailList = this.commodityDetailList
      that.form.id = this.id
      that.form.bigImgList = that.list
      that.form.buyStartTime  = that.form.buyStartTime.split(' ')[0]
      that.form.buyEndTime  = that.form.buyEndTime.split(' ')[0]
      that.form.businessId = that.form.businessId.toString()
      console.log(that.form);
      
      that.$http.post("/commodity/saveGroupBuying", that.form).then(function (response) {
        
        if (response.data.code == 200) {
          console.log('999');
          that.$router.push({path:'/commodity',query:{'pageNum':that.pageNum}})
          that.$message({
            type:'success',
            message: '保存成功'
          });
        } else {
          that.$message({
            type:'success',
            message: response.data.message
          });
        }
      });
    },
    close() {
      this.$router.push({path:'/commodity',query:{'pageNum':this.pageNum}})
    },
    add() {
      this.showDialog = true
    },
    addfl() {
      this.showDialogfl = true
    },
    handleAvatarSuccess(res, file) {
      console.log(file.response.data.url, '88888888');
      this.form.bigImg = file.response.data.url;
      console.log(this.form.bigImg, 99999999999);
    },
    handleAvatarSuccesstwo(res, file) {
      console.log(file.response.data.url, '88888888');
      this.form.buyVideo = file.response.data.url;

    },
    handleAvatarSuccesslogo(res, file) {
      console.log(file.response.data.url, '88888888');
      this.form.logoImg = file.response.data.url;

    },
    bc() {
      this.dia.isParent = '0'

      this.commodityDetailList.push(this.dia)
      this.dia = {
        isParent:'',
        content: '',
        price: '',
        number: ''
      }
      this.showDialog = false

    },
    bcfl(){
      this.dia.isParent = '1'
      this.commodityDetailList.push(this.dia)
      this.dia = {
        isParent:'',
        content: '',
        price: '',
        number: ''
      }
      this.showDialogfl = false
    },
    emitUpload() {},
    handelRemove(val){
      if(val == 'logo'){
        this.form.logoImg = ''
      }else{
        this.list.splice(val, 1,)
      }
    },
    scimg(){
      console.log(111);
      
    },
    searchsj(){
      var that = this;
      that.$http.post('/business/queryBusinessList',{
        businessName:that.name
      }).then(response => {
      console.log(response.data.data,'response.data.data');
      
        that.restaurants = response.data.data
        console.log(that.restaurants,'that.restaurants');
        
        
        
      })
    }
  }
};
</script>

<style lang="less" scoped>
// /deep/ .el-table__body tr.hover-row>td{
//      background-color: red !important;
// }

/deep/ .el-textarea__inner {
  height: 100px;
}

.diafour {
  margin-top: 20px;
}
/deep/.el-main {
  padding: 0 !important;
  height: 100% !important;
  background-color: #fff !important;
}
// /deep/ .el-form .el-input__inner {
//     border: 0px solid #b8becc;
// }

.box {
  background-color: #fff;
  padding-top: 20px;
  margin-right: 5px;

  .jbxx {
    .jbtop {
      height: 56px;
      background-color: #eef1f6;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 20px;
      margin: 0 10px 40px 10px;

      .icon {
        width: 6px;
        height: 19px;
        border-radius: 10px;
        background-color: #f77f1f;
        margin-right: 10px;
      }

      .tit {
        font-weight: 700;
      }
    }

  }

  .butt {
    display: flex;
    justify-content: space-between;
    padding: 50px 300px;
  }

  .tab {
    padding: 20px;
  }

  .dia {
    .diali {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .liname {
        width: 100px;
      }

      input {
        height: 30px;
      }
    }

  }
}

.bottom-but {
  margin-left: 50%;
  transform: translateX(-15%);
  margin-bottom: 40px;
}

/deep/ .avatar-uploader .el-upload {
    // border: 1px dashed #877b7b;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    border: 1px solid #877b7b;
    // display: block;
  }
  .ggli {
    margin-right: 20px;
  }
  /deep/ .el-tag--small {
    
    margin-right: 15px;
  }
  ::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
.imgss {
  margin: 5px;
}
.bigImg {
  width: 500px;
}

.fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding-top: 20px;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 2;
}
.container {
  padding: 5px;
  box-sizing: border-box;
  .box {
    width: 100%;

    background-color: #fff;
    font-size: 14px;
    border-radius: 5px;
    color: #333;
    .title {
      border-bottom: 1px solid #eee;
      padding: 10px;
      box-sizing: border-box;
      &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 20px;
        margin-right: 15px;
        background-color: #02a7f0;
        // 和标题对其
        vertical-align: middle;
      }
    }
    .content2 {
      padding: 0px 20px 20px 20px;
      box-sizing: border-box;
    }
    .content {
      padding: 0px 20px;
      box-sizing: border-box;

      .imgTitle {
        margin-top: 10px;
        box-sizing: border-box;
        font-weight: 700;
        padding-bottom: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #eee;
      }
      .imgbox {
        padding: 10px;
        box-sizing: border-box;
      }

      .fa {
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding-left: 15px;
        box-sizing: border-box;
        background-color: #e3f5fd;
        margin: 10px;
      }
    }
  }
}

.mp {
  margin-top: 15px;
}
.mp10 {
  margin-top: 10px;
}
.pd50 {
  padding: 0 50px;
  box-sizing: border-box;
}
.left10 {
  padding-left: 20px;
  box-sizing: border-box;
}
.tp5 {
  margin-top: 5px;
  width: 148px;
  text-align: center;
  font-weight: 700;
  color: #8c939d;
}
.imgs {
  width: 130px;
  height: 130px;
  margin: 5px;
}
.center {
  width: 600px;
  text-align: center;
  margin: 10px auto;
}

.lable {
  width: 130px;
  display: inline-block;
  text-align: end;
}

.imgBox {
  width: 148px;
  height: 148px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}
.img-icon {
  width: 148px;
  height: 148px;
  // width: 750px / 3;
  // height: 343px / 3;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.4);

  i {
    font-size: 26px;
    color: #fff;
  }
}

.avatar-uploader {
  min-width: 300px;
}

.avatar-uploader ::v-deep .el-upload {
  //background-color: #fbfdff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  border: 1px dashed #c0ccda;
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

/deep/.el-loading-spinner {
  margin-top: -74px;
}

.avatar {
  display: block;
  width: 148px;
  height: 148px;
  border-radius: 6px;
}

.imgBox {
  // width: 750px / 3;
  // height: 343px / 3;
  width: 148px;
  height: 148px;
  position: relative;
  margin-right: 5px;
  margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.table {
  .el-button {
    height: 20px !important;
    line-height: 6px !important;
  }
}

/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent !important;
}
// ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
//   background-color: #235bf5 !important;
// }
.text {
  text-align: center;
  margin-top: 10px;
}
::v-deep .el-table td {
  padding: 0 !important;
}
::v-deep .el-table th {
  padding: 0px !important;
}
.pdfbox {
  display: flex;

  .item {
    margin: 0 10px;
  }
}
.imgboxs {
  margin-left: 130px;
  margin-top: 5px;
  display: flex;
  & > div {
    margin: 0 10px;
  }
}
::v-deep .el-image {
  margin: 5px !important;
}

// .titlesss::before {
//   content: "*";
//   color: red;
//   position: absolute;
//   left: 2px;
// }
.titlesss {
  color: red;
  display: inline-block;
  margin-right: 3px;
}

.success {
  color: #62a003;
  font-weight: 700;
}

.error {
  color: #d6000a;
  font-weight: 700;
}
.shenhebox {
  height: 500px;
  overflow: auto;
}
.tag {
  width: 80px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
}
.tab {
  /deep/ .el-input__inner {
  text-align: center;
  border: none;
}

}
.list-img-box{
  width: 150px;
  height: 150px;
  border-radius: 6px;
  border: 1px dashed #c0ccda;
  color: #8c939d;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .upload-btn{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.upload-img{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
</style>